const Projects = {
    0 : {
        name : "Tandem Coding Quiz",
        github : "https://github.com/todayisfineforme/Nathan.Hall.Tandem.coding.challenge",
        link : "http://tandem-trivia-400.s3-website.us-east-2.amazonaws.com/",
        description : "A trivia training app built for the Tandem for 400 Coding Challenge. it generates a 10 question round from a predetermined list supplied at the beginning of the challenge. question order is shuffled as well as answer order within those questions.",
        image : "../images/projects/tandem_quiz.jpeg",
        background : "tandemQuiz"
    },
    1 : {
        name : "Employee Directory",
        github : "https://github.com/todayisfineforme/Employee-Directory",
        link : "http://employee.directory.demo.s3-website.us-east-2.amazonaws.com/",
        description : "React app that allows users to view a list of employees, and sort, filter and seach within it.",
        image : "../images/projects/employee_directory.jpeg",
        background : "employeeDirectory"
    },
    2 : {
        name : "Password Generator",
        github : "https://github.com/todayisfineforme/Password.Generator",
        link : "https://todayisfineforme.github.io/Password.Generator/",
        description : "Website that generates random passwords based on user inputs using prompts.",
        image : "../images/projects/password_generator.jpg",
        background : "passwordGenerator"
    },
    3 : {
        name : "Javascript Code Quiz",
        github : "https://github.com/todayisfineforme/Code.Quiz",
        link : "https://todayisfineforme.github.io/Code.Quiz/",
        description : "Simple Javascript Quiz written using javascript to demonstrate usage of JQuery and Dom Updates",
        image : "../images/projects/code_quiz.jpeg",
        background : "codeQuiz"
    },
    4 : {
        name : "Weather Dashboard",
        github : "https://github.com/todayisfineforme/Weather.Dashboard",
        link : "https://todayisfineforme.github.io/Weather.Dashboard/",
        description : "Html front page weather dashboard that utilizes the openWeather Api to search for and display current and 7 day forecasts.",
        image : "../images/projects/weather_dashboard.jpeg",
        background : "weatherDashboard"
    },
    5 : {
        name : "Work Day Scheduler",
        github : "https://github.com/todayisfineforme/Work.Day.Scheduler",
        link : "https://todayisfineforme.github.io/Work.Day.Scheduler/",
        description : "Webpage utilizing javascript and bootstrap that allows user to plan out and store their workday schedule in localstorage that persists between browser sessions.",
        image : "../images/projects/work_day_scheduler.jpg",
        background : "workDayScheduler"
    }
}

export default Projects;